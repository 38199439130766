<template>
  <div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full grid grid-cols-1 place-content-center">
    <div class="relative top-0 mx-auto border w-11/12 sm:w-4/5 lg:w-1/2 xl:w-1/3 shadow-lg rounded-md bg-white">

      <div v-if="stage == 'published'" class="mt-3 text-center">
        <div class="mx-auto flex items-center justify-center h-12 w-12 mt-4 mb-2 rounded-full bg-green-400">
          <svg class="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" ></path>
          </svg>
        </div>
        <h3 class="text-xl leading-6 font-medium text-gray-900">Successful!</h3>
        <div class="mt-2 px-7 py-3">
          <div class="text-lg text-gray-500 overflow-scroll" style="max-height: 60vh;">
            Story has been successfully published!
          </div>
        </div>
        <div class="items-center px-4 py-3">
          <button @click="$emit('toggle-modal', false)" class="px-4 py-2 bg-green-800 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-900 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-300">
            Close
          </button>
        </div>
      </div>

      <div v-else-if="stage == 'preview'" class="mt-3 mb-3 relative">
        <button @click="stage = ''" class="absolute right-0 top-0 mt-1 mr-5 text-xl opacity-50 hover:opacity-100">
          <i class="uil uil-multiply"></i>
        </button>
        <h3 class="text-lg px-5 pt-1 pb-2 border-b font-medium text-gray-900">Preview Story</h3>
        <div class="mt-2 px-7 py-3">
          <div class="text-sm text-gray-500 overflow-scroll border rounded-md overflow-hidden max-w-xs mx-auto" style="max-height: 60vh;">
            <video v-show="video.file" id="video-preview" class="w-full" controls playsinline style="max-height: 45vh;"/>
            <img v-if="image.src" :src="previewImage"/>
            <div class="p-4">
              {{property.name}}
              <h1 class="font-semibold text-xl my-2">{{title}}</h1>
              <div class="text-lg">
                {{text}}
                <a href="" v-for="tag in selectedTags" :key="tag" class="underline">#{{tag}} </a>
              </div>
            </div>
            <button v-if="!(callToAction === 'None')" class="px-4 py-2 bg-gray-600 text-white text-base font-medium w-full shadow-sm hover:bg-gray-800 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-300">
              {{callToAction}}
            </button>
          </div>
        </div>

        <div class="flex px-4 py-3">
          <div class="flex-none mr-1">
            <button @click="stage = ''" class="px-12 py-2 bg-green-700 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-900 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-300">
              Back
            </button>
          </div>
          <div class="flex-grow">
            <button v-if="!publishing" @click="publish" class="px-4 py-2 bg-green-800 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-900 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-300">
              Publish
            </button>
            <button v-else type="button" class="inline-flex items-center justify-center px-4 py-2 bg-green-800 text-white text-base font-medium rounded-md w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-green-300 transition ease-in-out duration-150 cursor-not-allowed" disabled="">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Publishing...
            </button>
          </div>
        </div>
      </div>

      <div v-else class="mt-3 mb-3 relative">
        <button @click="$emit('toggle-modal', false)" class="absolute right-0 top-0 mt-1 mr-5 text-xl opacity-50 hover:opacity-100">
          <i class="uil uil-multiply"></i>
        </button>
        <h3 class="text-lg px-5 pt-1 pb-2 border-b font-medium text-gray-900">Add a new Story</h3>

        <div class="px-5 py-5 text-sm text-gray-500 overflow-scroll" style="max-height: 80vh;">
          <div class="relative upload-example overflow-hidden mb-8">

            <!-- Placeholder for video thumbnail -->
            <div class="hidden"><canvas id="canvas"></canvas></div>

            <div v-if="image.src || video.file" class="text-white absolute top-0 right-0 z-10 text-xl text-center m-2 pt-0.5 pr-0.5 bg-black opacity-75 hover:opacity-100 rounded-full w-8 h-8 cursor-pointer" title="Clear" @click="reset()">
              <i class="uil uil-times"></i>
            </div>

            <video v-show="video.file" id="video-preview" class="w-full" controls playsinline style="max-height: 45vh;"/>

            <div v-if="image.src && !video.file" class="mx-auto" style="width: 100%; height: auto;">
              <div style="position: relative; width: 100%; padding-top: -75%; position: relative; overflow: hidden" class="bg-gray-100">
                <cropper ref="cropper" :src="image.src" :resize-image="{ wheel: false }" :stencil-props="{}" :auto-zoom="true" image-restriction="stencil"/>
                <div class="text-white absolute bottom-0 right-0 z-10 text-xl text-center m-2 pt-0.5 pr-0.5 bg-black opacity-75 hover:opacity-100 rounded-full w-8 h-8 cursor-pointer" title="Rotate Image" @click="rotate(-90)">
                  <i class="uil uil-crop-alt-rotate-right"></i>
                </div>
              </div>
            </div>

            <div v-else-if="!video.file" class="border-2 rounded-md border-dashed bg-gray-100 text-center mx-auto leading-5 py-8">
              <button class="upload-button" @click="$refs.file.click()">
                <input ref="file" type="file" accept="image/jpeg, video/*" @change="handleFileUpload($event)"/>
                <img class="w-8" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAClUlEQVRoge2Zv2sUQRTHP4nc5s4g2BqIaJQUWmijCLGIjRhs/CNs7KPBFDFJZalYqYXYSJqAIFhZeQn6H6hJ0JCECEGj4g8IHMRidvHd3MxmbndmVNgvDJfd+973ve/O7ps3G6hQoUJZ7AdmgRWgBex6Hq1UewZohEj+VYCkbWPRt4nZiMln45ZPAytC+CaQ+BRPkQCTIs6yT3F5z4dIPkOfiNPyKSynNsNietwsoWvSMMWip0SQTFTXMp0LpttbMEAeFtLPMjPgrBFiBkIh2gxERWXAgFBVyIj/vgr9q2iiEn4pzgVZB2LCuQo1UK1r6PZ4mgDtcQO1iMTqLBeAumNuxltIx0zE5LMxZcjDuRfSIdvj60Atj1wQNeCGiLNk4JiSdTIQqz1ORBxTe+xchXQ4kTyhm1jW/UDoVuIysAGsA2MFNRJgXBy/zyP7noF1obfmEKvoQ58rWga+DexZdn0bGEOZWAMuOcQyJd1CbeSncFgz8gwcBZ4C26jSd41yrUiQgmETPQ1s0Xl1HlK8EEQzcBZ11W335WNgn+BfBd4CX4BnwPEuYpWGLnoe+CbOfQbOpUlL7hyq3N2j0+A2cOZvGLgA/BDHW8CplNcL3Nf4q4bks/EVGIlt4Jf4exM4oXF7gLuWhJ+grrp8br4DozENyPo9bOHXgHca/xF/nomTwEfx3U/gYkwDq8AxCzcB5jX+Azqr0jDtC9oOcCWGgSVg0MKrA881/h3s68IQ8IF2E8ENHLJw+oEXGve2g/Zh1Ipquk29Ya/9wAFUjy6Dd/MPhwHgjfZ7r6/L5Y5sgnYTB4HXWvCJAjGOAJ+EhmlHVhjTmKc45Mhtj7tF7LcSTdzfSjijjroqy4R7L+TcHleoUCEfvwFEhkEheZkP6AAAAABJRU5ErkJggg=="/>
              </button>
            </div>
          </div>

          <span class="block text-gray-600 text-sm text-left font-bold mb-1">Story</span>

          <div class="flex mb-3 mx-auto">
            <input class="self-center mr-1" type="radio" name="type" value="offer" checked>
            <label class="self-center mr-6" for="inlineRadio10">Special Offer</label>
            <input class="self-center mr-1" type="radio" name="type" value="news">
            <label class="self-center mr-6" for="inlineRadio20">News</label>
            <input class="self-center mr-1" type="radio" name="type" value="guest">
            <label class="self-center mr-6" for="inlineRadio30">Guest Promotion</label>
            <input class="self-center mr-1" type="radio" name="type" value="event">
            <label class="self-center" for="inlineRadio30">Event</label>
          </div>

          <div class="mb-2">
            <input v-model="title" placeholder="Title" class="flex-grow px-3 py-2 text-lg bg-gray-100 border rounded w-full text-gray-900 focus:outline-none focus:border-green-100"/>
          </div>

          <div class="mb-2">
            <textarea v-model="text" ref="textarea" placeholder="Your story" required class="block w-full h-24 px-3 py-2 text-lg bg-gray-100 border rounded w-full text-gray-900 focus:outline-none focus:border-green-100"></textarea>
          </div>

          <div class="mb-2">
            <v-select v-model="category" label="name" :options="allCategories" class="select" :clearable="false" placeholder="Category"></v-select>
          </div>

          <div class="mb-4">
            <v-select v-model="selectedTags" placeholder="Tags (max 3)" :options="tags" taggable multiple push-tags :selectable="() => selectedTags.length < 3" class="tags"/>
          </div>

          <div class="mb-4">
            <span class="block text-gray-600 text-sm text-left font-bold mb-1">Call to Action</span>
            <v-select v-model="callToAction" :options="['None', 'More information', 'Read more', 'Book now', 'Visit website', 'Buy now']" class="select" :clearable="false"></v-select>
            <input v-if="!(callToAction === 'None')" v-model="callToActionLink" placeholder="https://call.to.action/link" class="flex-grow px-3 py-2 text-lg bg-gray-100 border rounded w-full text-gray-900 focus:outline-none focus:border-green-100"/>
          </div>

          <div class="mb-4">
            <span class="block text-gray-600 text-sm text-left font-bold mb-1">Story availability</span>
            <v-date-picker :model-config="modelConfig" :value="dateRange" v-model="dateRange" mode="dateTime" :popover="{ visibility: 'click' }" :min-date="new Date()" is-range is24hr>
              <template v-slot="{ inputValue, inputEvents, isDragging }">
                <div class="flex flex-col sm:flex-row justify-start items-center">
                  <div class="relative flex-grow">
                    <svg class="text-gray-600 w-4 h-full mx-3 absolute pointer-events-none" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                    </svg>
                    <input class="flex-grow pl-10 pr-2 py-2 text-base bg-gray-100 border rounded w-full text-gray-900 focus:outline-none focus:border-green-100" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start"/>
                  </div>
                  <span class="flex-shrink-0 m-2">
                    <svg class="w-4 h-4 stroke-current text-gray-600" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"/>
                    </svg>
                  </span>
                  <div class="relative flex-grow">
                    <svg class="text-gray-600 w-4 h-full mx-2 absolute pointer-events-none" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                    </svg>
                    <input class="flex-grow pl-10 pr-2 py-2 text-base bg-gray-100 border rounded w-full text-gray-900 focus:outline-none focus:border-green-100" :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end"/>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <button @click="preview" class="px-4 py-2 bg-green-800 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-900 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-300" >
            Preview
          </button>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

function getMimeType (file, fallback = null) {
  const byteArray = (new Uint8Array(file)).subarray(0, 4)
  let header = ''
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16)
  }
  switch (header) {
    case '89504e47': return 'image/png'
    case '47494638': return 'image/gif'
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8': return 'image/jpeg'
    default: return fallback
  }
}

export default {
  name: 'AddPost',
  components: {
    Cropper
  },
  props: ['property'],
  data () {
    return {
      allCategories: [],
      category: '',
      type: null,
      publishing: false,
      stage: '',
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0
      },
      video: {
        file: ''
      },
      image: {
        src: null,
        type: null
      },
      filename: '',
      title: '',
      text: '',
      selectedTags: [],
      tags: ['foodies', 'restaurant', 'spa', 'wellness', 'news', 'co-working', 'lifestyle'],
      callToAction: 'None',
      callToActionLink: '',
      start: '',
      end: '',
      modelConfig: {
        start: {
          timeAdjust: '00:00:00'
        },
        end: {
          timeAdjust: '23:59:59'
        }
      },
      file: null,
      videofile: null
    }
  },
  mounted () {
    const startDate = new Date()
    this.start = startDate.getTime()
    const endDate = new Date()
    endDate.setDate(startDate.getDate() + 7)
    this.end = endDate.setHours(23, 59, 59, 0)
    for (var i = 0; i < this.categories.length; i++) {
      var category = this.categories[i]
      const c = { id: category.$id, name: category.Name }
      this.allCategories.push(c)
    }
  },
  watch: {
    text: function () {
      this.resize()
    },
    video: function () {
      setTimeout(() => this.handleCreateThumbnail(), 1000)
    }
  },
  computed: {
    ...mapGetters(['categories', 'getAccount', 'posts', 'properties', 'types']),
    dateRange: {
      set (val) {
        this.start = val.start.getTime()
        this.end = val.end.getTime()
      },
      get () {
        const range = {
          start: this.start,
          end: this.end
        }
        return range
      }
    }
  },
  methods: {
    ...mapActions(['fetchPosts', 'addPost', 'clearPosts']),
    onPickFile () {
      this.$refs.fileInput.click()
    },
    resize () {
      const { textarea } = this.$refs
      textarea.style.height = textarea.scrollHeight + 4 + 'px'
    },
    preview () {
      if (this.image.src) {
        const { coordinates, canvas } = this.$refs.cropper.getResult()
        this.coordinates = coordinates
        this.previewImage = canvas.toDataURL()
        canvas.toBlob((blob) => {
          const filename = this.slugify(this.property.name) + '.' + this.filename.substr(this.filename.lastIndexOf('.') + 1)
          this.file = new File([blob], filename, { type: blob.type })
        }, this.image.type)
      }
      this.stage = 'preview'
    },
    handleFileUpload (event) {
      this.reset()
      var file = event.target.files[0]
      if (file.type.match('image.*')) {
        this.handlePreviewImage(event)
      }
      if (file.type.match('video.*')) {
        this.videofile = event.target.files[0]
        this.video.file = event.target.files[0]
        const blobURL = URL.createObjectURL(file)
        const video = document.getElementById('video-preview')
        video.src = blobURL
      }
    },
    handlePreviewImage (event) {
      const { files } = event.target
      if (files && files[0]) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        const blob = URL.createObjectURL(files[0])
        this.filename = files[0].name
        const reader = new FileReader()
        reader.onload = (e) => {
          this.image = {
            src: blob,
            type: getMimeType(e.target.result, files[0].type)
          }
        }
        reader.readAsArrayBuffer(files[0])
      }
    },
    handleCreateThumbnail () {
      const video = document.getElementById('video-preview')
      const videoCanvas = document.getElementById('canvas')
      videoCanvas.width = video.videoWidth
      videoCanvas.height = video.videoHeight
      videoCanvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      videoCanvas.toBlob((blob) => {
        this.file = new File([blob], 'fileName.jpg', { type: 'image/jpeg' })
      }, 'image/jpeg')
    },
    async publish () {
      this.publishing = true
      const userId = this.getAccount.$id
      const data = {
        createdAt: Date.now(),
        updatedAt: Date.now(),
        title: this.title,
        text: this.text,
        tags: this.selectedTags,
        callToAction: this.callToAction,
        callToActionLink: this.callToActionLink,
        start: this.start,
        end: this.end,
        propertyId: this.property.$id,
        propertyname: this.property.name,
        latitude: this.property.latitude,
        longitude: this.property.longitude,
        categoryId: this.category.id,
        typeId: this.type
      }
      if (!userId) {
        console.log('User id is null')
      } else {
        const read = ['role:all']
        const write = [`user:${userId}`]
        const file = this.file
        const video = this.videofile
        const addPost = await this.addPost({
          file,
          video,
          data,
          read: read,
          write: write
        })
        if (addPost === true) {
          this.stage = 'published'
        }
        this.publishing = false
      }
    },
    reset () {
      this.file = null
      this.videothumb = null
      this.video = {
        file: null
      }
      this.image = {
        src: null,
        type: null
      }
    },
    rotate (angle) {
      this.$refs.cropper.rotate(angle)
    },
    slugify (string) {
      var slug = ''
      var titleLower = string.toLowerCase()
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
      slug = slug.replace(/đ/gi, 'd')
      slug = slug.replace(/\s*$/g, '')
      slug = slug.replace(/\s+/g, '-')
      return slug
    }
  },
  destroyed () {
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src)
    }
    if (this.video.file) {
      URL.revokeObjectURL(this.video.file)
    }
  }
}
</script>
