<template>

  <section class="container min-h-screen mx-auto flex">
    <div class="flex-grow flex flex-col justify-center p-6">

      <h1 class="text-2xl md:text-3xl lg:text-4xl font-bold">{{property.name}}</h1>

      <button @click="$router.push('/stories')" class="flex items-center hover:underline">
        <div class="pr-1 opacity-50">
          <i class="text-xl uil uil-arrow-left"></i>
        </div>
        <div>Return to stories overview</div>
      </button>

      <p class="hidden my-8 text-lg md:text-xl lg:text-2xl font-medium">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, nostrum dolores eius dicta iure odio corrupti debitis nobis iste quae! Architecto quae deleniti sapiente maxime fugiat officiis quam praesentium ullam?
      </p>

      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 my-8">
        <button @click="modal = !modal" class="bg-white p-8 pt-10 border-dashed border-2 rounded-lg opacity-60 hover:opacity-100 transition duration-200 ease-in-out transform hover:scale-105">Add a new Story +</button>
        <simple-post v-for="post in posts" :key="post.$id" :post="post"/>
      </div>

      <add-post v-if="modal" :property="property" v-on:toggle-modal="modal = $event"/>

    </div>
  </section>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Query } from 'appwrite'
import SimplePost from '../components/SimplePost.vue'
import AddPost from '../components/AddPost.vue'

export default {
  name: 'StoriesByProperty',
  components: {
    SimplePost,
    AddPost
  },
  data () {
    return {
      modal: false
    }
  },
  mounted () {
    const queries = []
    queries.push(Query.equal('propertyId', this.property.$id))
    this.fetchPosts({
      queries: queries
    })
  },
  computed: {
    ...mapGetters(['properties', 'posts', 'getAccount']),
    property () {
      const index = this.properties.findIndex(x => x.$id === this.$route.params.property_id)
      return this.properties[index]
    }
  },
  methods: {
    ...mapActions(['fetchPosts', 'clearPosts']),
    updateparent (variable) {
      alert('nu')
    }
  },
  destroyed () {
    this.clearPosts()
  }
}
</script>
