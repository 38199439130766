<template>
  <div class="bg-white p-8 border-dashed border-2 rounded-lg transition duration-200 ease-in-out transform hover:scale-105">
    {{ post.title }}<br/>
    <span class="opacity-50 text-sm">{{ convertDate(post.createdAt) }}</span>
    <button @click.prevent="handleDelete(post)" class=" absolute top-0 right-0 p-2 ml-2 text-lg focus:outline-none transition duration-75 ease-in-out transform opacity-25 hover:opacity-100">
      <i class="uil uil-trash-alt"></i>
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'SimplePosts',
  props: ['post'],
  methods: {
    ...mapActions(['updatePost', 'deletePost', 'deleteFile']),
    handleCheck (post) {
      const data = {
        name: post.slogan + '-'
      }
      const payload = {
        data: data,
        documentId: post.$id,
        read: post.$permissions.read,
        write: post.$permissions.write
      }
      this.updatePost(payload)
    },
    handleDelete (post) {
      if (confirm('Are you sure you want to delete \'' + post.title + '\'?')) {
        this.deleteFile(post.image)
        this.deletePost(post.$id)
      }
    },
    convertDate (epoch) {
      var date = new Date(epoch)
      return date.toLocaleString()
    }
  }
}
</script>
